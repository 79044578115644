import type {
  SchemaOptions,
  ValidationFunc,
} from "~/components/Forms/Fields/Base/InputTypesProps";
import { createSignal, splitProps } from "solid-js";
import {
  maxLength,
  minLength,
  optional,
  safeParse,
  string,
  pipe,
} from "valibot";
import ValidationErrors from "./Base/ValidationErrors";
import {
  InputTypeLocationAutoComplete,
  type InputTypeLocationAutoCompleteProps,
} from "./Base/InputTypeLocationAutoComplete";

const LABEL = "Ville";
const HELP = undefined;
const IS_REQUIRED_BY_DEFAULT = true;
const MIN_LENGTH = 3;
const MAX_LENGTH = 100;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function LocationSchema(options?: SchemaOptions & { prefix?: string }) {
  const prefix = options?.prefix || "";
  const schema = {} as Record<string, ReturnType<typeof pipe>>;

  schema[`${prefix}location`] = pipe(
    string(),
    minLength(
      MIN_LENGTH,
      `La ${LABEL.toLowerCase()} doit comporter au moins ${MIN_LENGTH} caractères`,
    ),
    maxLength(
      MAX_LENGTH,
      `Lettres, chiffres et signes de ponctuation autorisés. ${MAX_LENGTH} caractères max.`,
    ),
  );

  if (options?.optional) {
    schema[`${prefix}city`] = optional(string());
  } else {
    schema[`${prefix}city`] = string(
      "Veuillez préciser votre ville de résidence.",
    );
  }

  schema[`${prefix}department`] = optional(string());
  schema[`${prefix}region`] = optional(string());
  schema[`${prefix}postal_code`] = optional(string());
  schema[`${prefix}lat`] = optional(string());
  schema[`${prefix}lng`] = optional(string());
  schema[`${prefix}country`] = optional(string());

  return schema;
}

export function Location(props: InputTypeLocationAutoCompleteProps) {
  const prefix = () => props.fieldNamePrefix || "";
  const [local, others] = splitProps(props, ["label", "help", "required"]);
  const [validationErrors, setValidationErrors] = createSignal<string[]>([]);

  const validate: ValidationFunc = (input) => {
    const schema = LocationSchema({ prefix: prefix() });
    const locationSchema = schema[`${prefix()}location`];
    const ret = safeParse(locationSchema, input);
    ret.success
      ? setValidationErrors([])
      : setValidationErrors(ret.issues.map((issue) => issue.message));
  };

  return (
    <>
      <div class={`form-item-${props.name}`}>
        <InputTypeLocationAutoComplete
          label={local.label || LABEL}
          help={local.help || HELP}
          maxLength={MAX_LENGTH}
          validateFn={validate}
          aria-invalid={validationErrors().length > 0}
          aria-errormessage={`err-${props.name}`}
          required={
            local.required !== undefined
              ? local.required
              : IS_REQUIRED_BY_DEFAULT
          }
          {...others}
        />
        <ValidationErrors errors={validationErrors()} field={props.name} />
      </div>
    </>
  );
}
